import { ListedResponse } from '@eva/emf/app/types/common-types';
import { requestBackend } from '@eva/emf/app/utils/request';
import type { WorkflowDto, JobDto } from '@eva/types/dto';

export interface FetchRecommendedJobsBody {
  pipelineWorkflowIds: number[];
  limit?: number;
  offset?: number;
}

export const fetchRecommendedJobsWorkflows = (): Promise<ListedResponse<WorkflowDto>> =>
  requestBackend('/my/candidate/jobs/workflows');

export const fetchRecommendedJobsByWorkflowId = (body: FetchRecommendedJobsBody): Promise<ListedResponse<JobDto>> =>
  requestBackend('/my/candidate/jobs/search', {
    method: 'POST',
    body: JSON.stringify(body),
  });

export const applyToRecommendedJob = (code: string): Promise<unknown> =>
  requestBackend(`/my/candidate/jobs/apply/${code}`, {
    method: 'POST',
  });

export const fetchRecommendedJobById = (jobId: number): Promise<JobDto> =>
  requestBackend(`/my/candidate/jobs/${jobId}`);
