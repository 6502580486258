import { memo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { PipelineDto } from '@eva/types/dto';
import { grayColor } from 'shared/constants';

import noCompanyImage from 'assets/images/no-company.png';

const ArchivedPipelines = ({ sortedPipelines, selectedPipeline, discussJob, selectPipeline }) => {
  const currentStageRenderer = (
    <div
      className="pipeline-group text-left"
      style={{
        background: grayColor,
      }}
    >
      <h4>Archived</h4>
    </div>
  );

  const content = sortedPipelines.map((pipeline: PipelineDto) => {
    const workflowState = get(pipeline, 'workflowProcess.workflowState');

    return (
      <div
        className={`job-pipeline job-item ${
          selectedPipeline?.pipelineId === pipeline.pipelineId ? 'active' : ''
        } media`}
        key={pipeline.pipelineId}
        onClick={() => selectPipeline(pipeline)}
      >
        <div className="media-left">
          <div className="company-img-crop">
            <img
              className="media-object"
              role="presentation"
              src={get(pipeline, 'job.avatar.url') || get(pipeline, 'job.company.avatar.url') || noCompanyImage}
            />
          </div>
        </div>
        <div className="media-body">
          <div className="text-left">
            <i
              className="ll-icon ll-chat fa-2x pull-right text-dark-blue"
              style={{
                fontSize: '18px',
              }}
              onClick={(evt) => discussJob(evt, pipeline.job.code)}
            />
            <h3>{pipeline.job.title || '(No title)'}</h3>
            <div className="pull-right margin-top">
              <span
                className="badge"
                style={{
                  background: workflowState.color || grayColor,
                }}
              >
                {workflowState?.candidateLabel || 'No label'}
              </span>
            </div>
            <div className="job-company margin-top">{get(pipeline, 'job.company.name')}</div>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      {currentStageRenderer}
      {content}
    </div>
  );
};

ArchivedPipelines.propTypes = {
  selectPipeline: PropTypes.func.isRequired,
  discussJob: PropTypes.func.isRequired,
  sortedPipelines: PropTypes.array.isRequired,
  selectedPipeline: PropTypes.object.isRequired,
};

// eslint-disable-next-line import/no-default-export
export default memo(ArchivedPipelines);
