import { FormUploadCvValues } from '@eva/emf/app/shared/ui/Modal/ModalUploadCv';
import { FormUploadFileValues } from '@eva/emf/app/shared/ui/Modal/ModalUploadFile';
import { requestBackend } from '@eva/emf/app/utils/request';
import type { CandidateFileDto, ResumeDto } from '@eva/types/dto';

export const createCandidateFile = async (
  _entityId: number,
  values: Partial<FormUploadFileValues>,
): Promise<CandidateFileDto> => {
  const {
    files: [file],
  } = values;

  const body = {
    filename: file.name,
    content: file.content,
    expiryDate: values.expiryDate || undefined,
    issueDate: values.issueDate || undefined,
    verified: values.verified,
    attachmentTypeId: values.fileType?.value,
  } as CandidateFileDto;

  return requestBackend(`/my/candidate-profile/attachments`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const updateCandidateFile = (
  _entityId: number,
  attachmentId: number,
  values: Partial<FormUploadFileValues>,
): Promise<CandidateFileDto> => {
  const body = {
    title: values.title,
    verified: values.verified,
    expiryDate: values.expiryDate || null,
    issueDate: values.issueDate || null,
    attachmentTypeId: values.fileType?.value,
  } as CandidateFileDto;

  return requestBackend(`/my/candidate-profile/attachments/${attachmentId}`, {
    method: 'PUT',
    body: JSON.stringify(body),
  });
};

export const deleteCandidateFile = (_entityId: number, attachmentId: number): Promise<any> =>
  requestBackend(`/my/candidate-profile/attachments/${attachmentId}`, {
    method: 'DELETE',
  });

export const signCandidateFileUrl = async (_entityId: number, fileId: number): Promise<string> =>
  requestBackend(`/my/candidate-profile/attachments/${fileId}/sign`, {
    method: 'POST',
  }).then(({ url }: { url: string }) => url);

export const createResume = async (_entityId: number, values: Partial<FormUploadCvValues>): Promise<ResumeDto> => {
  const {
    files: [file],
  } = values;

  const body = {
    filename: file.name,
    content: file.content,
    parse: values.parse,
  };

  return requestBackend(`/my/candidate-profile/resumes`, {
    method: 'POST',
    body: JSON.stringify(body),
  });
};

export const signCandidateResumeUrl = async (_entityId: number, resumeId: number): Promise<string> =>
  requestBackend(`/candidates/${_entityId}/resumes/${resumeId}/sign`, {
    method: 'POST',
  }).then(({ url }: { url: string }) => url);

export const parseResume = async (_entityId: number, resumeId: number): Promise<ResumeDto> =>
  requestBackend(`/my/candidate-profile/resumes/${resumeId}/parse`, {
    method: 'GET',
  });

export const deleteResume = async (_entityId: number, resumeId: number): Promise<any> =>
  requestBackend(`/my/candidate-profile/resumes/${resumeId}`, {
    method: 'DELETE',
  });
