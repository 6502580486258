/* eslint-disable no-restricted-properties */
import 'react-toastify/dist/ReactToastify.css';
import '@eva/emf/app/assets/css/luckyfont.css';
import '@eva/emf/app/assets/css/linearicons.css';
import './styles/reset.css';
import './styles/style.css';

import PropTypes from 'prop-types';
import React, { CSSProperties } from 'react';
import flatMap from 'lodash/flatMap';
import ms from 'ms';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { renderRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { requestBackend } from '@eva/emf/app/utils/request';
import { watchTokenChange } from '@eva/emf/app/utils/window';
import { GenericContext } from '@eva/emf/app/shared/constants';
import { windowLocationReload } from '@eva/emf/app/shared/functions';
import { PageError } from '@eva/emf/app/shared/ui/Error';
import { routesNames, alwaysAllowedOperations, evaSpinner, localHostnames, toastContainerId } from 'shared/constants';
import {
  getParsedUser,
  isAgentIntersect,
  isCandidateIntersect,
  isMobileMode,
  prepareSelector,
  replaceUrl,
  logError,
} from 'shared/functions';

import Helmet from 'containers/Helmet';
import { CardCustomFields } from 'containers/CandidateProfile/CardCustomFields';
import { getCandidateProfileSections } from 'containers/CandidateProfile/sections';

import { loadCustomComponents } from '../../shared/functions';

import { signOut, loadSettings, loadPermissions, loadUserProfile, updateUserProfile } from './actions';
import ModalSLAAccepted from './ModalSLAAccepted';
import { connectSocket, genericWrapper, storageEvent } from './functions';

const usedOperations = {};

const alertStyle: CSSProperties = {
  position: 'fixed',
  top: '20px',
  right: '20px',
  zIndex: 9999,
  boxShadow: '0 3px 6px rgba(0,0,0,0.3)',
};

class App extends React.Component<any, any> {
  usedToken: any;
  windowResizeHandler: any;
  storageEventHandler: any;
  unmounted: any;
  slaProcessed: any;
  modalSLAAccepted: any;
  socketEventsConnected: any;

  props: any;
  state: any = {
    mobileMode: isMobileMode(),
  };

  getChildContext() {
    const { settings, user, location } = this.props;
    const { socket } = this.state;

    return {
      settings,
      user,
      getUserProfile: () => this.props.userProfile,
      socket,
      getSectionsOptions: this.getSectionsOptions,
      setTopContainerRenderer: this.setTopContainerRenderer,
      isAllowedOperation: this.isAllowedOperation,
      location,
      router: this.getRouter(),
      loadCustomComponents,
    };
  }

  UNSAFE_componentWillMount() {
    const { user, dispatchLoadSettings, dispatchLoadPermissions, dispatchLoadUserProfile } = this.props;

    dispatchLoadSettings();

    if (user.userId && isCandidateIntersect(getParsedUser().accountTypes)) {
      connectSocket(this);
      dispatchLoadPermissions();
      dispatchLoadUserProfile();
    }

    // NOTE Monkey patch
    // eslint-disable-next-line no-extend-native
    Array.prototype.flatMap = function (iteratee) {
      return flatMap(this.map(iteratee));
    };

    this.usedToken = localStorage.token;

    window.setNotificationComponent = this.setNotificationComponent;

    watchTokenChange();
  }

  componentDidMount() {
    this.windowResizeHandler = this.handleWindowResize.bind(this);
    this.storageEventHandler = storageEvent.bind(this);

    const emitOnline = () => {
      const { socket } = this.state;

      if (socket && !this.unmounted) {
        socket.emit('online');
      }
      setTimeout(() => emitOnline(), ms('30s'));
    };
    emitOnline();

    window.addEventListener('storage', this.storageEventHandler, false);

    window.addEventListener('resize', this.windowResizeHandler, true);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { user, dispatchLoadSettings, dispatchLoadUserProfile } = nextProps;

    if (!this.props.user.userId && user.userId) {
      connectSocket(this);
      dispatchLoadUserProfile();
      dispatchLoadSettings(isAgentIntersect);
    }

    if (!this.usedToken) {
      this.usedToken = localStorage.token;
    }
  }

  componentDidUpdate() {
    const { userProfile } = this.props;

    if (isAgentIntersect(userProfile)) {
      if (!userProfile.slaAccepted && !this.slaProcessed) {
        this.checkSLA();
      }
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    window.removeEventListener('resize', this.windowResizeHandler, true);
    this.disconnectSocket();
  }

  getRouter = () => {
    const { history } = this.props;

    return history;
  };

  getSectionsOptions = () => {
    const { settings } = this.props;

    const sectionsOptions = getCandidateProfileSections();

    const customFieldsModel = settings.customFieldsModel;
    const myCandidateProfileConfig = customFieldsModel?.uiSchema?.['#myCandidateProfileConfig'];
    if (this.isAllowedOperation('myProfile-customFields-view') && myCandidateProfileConfig) {
      sectionsOptions.forEach((sectionsOption) => {
        const sectionCustomFieldsDescription = myCandidateProfileConfig
          .filter(({ section }) => section === sectionsOption.sectionKey)
          .sort((prev: any, cur: any) => (prev.index > cur.index ? 1 : -1));
        sectionCustomFieldsDescription.forEach(({ cardLabel, fieldKeys, index }) => {
          sectionsOption.paneSettings.splice(index, 0, {
            Cards: (props: any) => <CardCustomFields cardLabel={cardLabel} fieldKeys={fieldKeys} {...props} />,
          });
        });
      });
    }

    return sectionsOptions;
  };

  setNotificationComponent = (errorComponent: any, forceUpdate: any) => {
    this.setState({ errorComponent });
    if (forceUpdate) {
      this.forceUpdate();
    }
  };

  setTopContainerRenderer = (topContainerRenderer: any, headerSearchMode = false) => {
    this.setState({
      topContainerRenderer,
      headerSearchMode,
    });
  };

  isAllowedOperation = (operation: string) => {
    const { allowedOperationsNew } = this.props;

    if (!operation || typeof operation !== 'string') {
      throw Error('Operation must be a string');
    }

    const trackAndReturn = (result: any, comment?: any) => {
      usedOperations[operation] = result;
      localStorage.usedOperations = Object.entries(usedOperations)
        .sort(([prevKey], [nextKey]) => (prevKey > nextKey ? 1 : -1))
        .map(([key, value]) => `${key}: ${value}${comment ? ` (${comment})` : ''}`)
        .join('\n');
      return result;
    };

    const disallowedOperations = (localStorage.disallowedOperations || '').split(',');
    if (disallowedOperations.includes(operation)) {
      return trackAndReturn(false, 'disallowedOperations');
    }

    const allowedOperations = [...(localStorage.allowedOperations || '').split(','), ...alwaysAllowedOperations];
    if (allowedOperations.includes(operation)) {
      return trackAndReturn(true, 'allowedOperations');
    }

    if (localStorage.disableAllowance) {
      return trackAndReturn(true, 'disableAllowance');
    }

    return trackAndReturn(allowedOperationsNew.includes(operation));
  };

  redirectToNylas = () => {
    const {
      user: { userId },
    } = this.props;

    requestBackend('/nylas/oauth', {
      method: 'POST',
      body: JSON.stringify({
        userId,
        email: true,
        calendar: true,
      }),
    }).then(({ redirectURL }: any) => {
      if (this.unmounted) {
        return;
      }
      replaceUrl(redirectURL);
    });
  };

  checkSLA() {
    if (!this.modalSLAAccepted || this.slaProcessed) {
      return;
    }
    this.slaProcessed = true;
    const { dispatchLoadUserProfile } = this.props;
    this.modalSLAAccepted.open().then(
      () => dispatchLoadUserProfile(),
      (err) => logError(err),
    );
  }

  disconnectSocket() {
    const { socket } = this.state;

    if (socket) {
      if (!this.unmounted) {
        this.setState({ socket: null });
      }
    }
  }

  handleWindowResize() {
    const { mobileMode } = this.state;

    setTimeout(() => {
      if (mobileMode !== isMobileMode()) {
        this.setState({ mobileMode: !mobileMode });
      }
    });
  }

  connectSocketEvents(socket: any) {
    const { user } = this.props;

    if (this.unmounted) {
      return;
    } else if (this.socketEventsConnected) {
      logError('Socket events already connected!');
      return;
    }

    this.socketEventsConnected = true;

    if (!isAgentIntersect(user) && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => socket.emit('position', position),
        (error) => socket.emit('position', { error: error.message }),
        { timeout: 10000 },
      );
    }
  }

  render() {
    const {
      settings: { candidateBranding },
      loadingSettings,
      loadSettingsError,
      user,
      route,
      settings,
      dispatchSignOut,
      loadUserDetailsError,
    } = this.props;

    const { errorComponent, mobileMode, connectionCanBeRestored, disconnected, socket } = this.state;

    const router = this.getRouter();

    if (loadUserDetailsError) {
      return <PageError />;
    }

    if (loadingSettings) {
      return genericWrapper(evaSpinner);
    }

    if (loadSettingsError) {
      return genericWrapper(<div>Unable to open the page, please refresh the page in a few moments</div>);
    }

    return (
      <GenericContext.Provider value={this.getChildContext() as any}>
        {!window.location.pathname.includes(routesNames.jobDetails) && <Helmet candidateBranding={candidateBranding} />}

        <ModalSLAAccepted key="modalSLAAccepted" ref={(ref) => (this.modalSLAAccepted = ref)} />

        <ToastContainer hideProgressBar newestOnTop={false} enableMultiContainer containerId={toastContainerId} />

        {errorComponent && (
          <div
            className="alert text-center"
            style={{
              ...alertStyle,
              background: 'white',
              borderColor: '#DEE7EB',
              width: '300px',
            }}
          >
            <button
              type="button"
              style={{
                position: 'absolute',
                right: '10px',
                top: '10px',
              }}
              className="btn-tool-box"
              onClick={() => window.setNotificationComponent(null)}
            >
              <i className="lnr lnr-cross" />
            </button>
            {errorComponent}
          </div>
        )}
        {!errorComponent && disconnected && (
          <div
            className="alert alert-warning text-center"
            style={{
              ...alertStyle,
            }}
          >
            <i
              className={`lnr lnr-warning fa-3x
              ${disconnected || !connectionCanBeRestored ? 'blink-me' : ''}`}
            />
            <h4
              className="margin-bottom"
              style={{
                whiteSpace: 'pre-wrap',
                marginTop: '10px',
              }}
            >
              {translate('Connection with the server was lost')}
              {connectionCanBeRestored && <div className="margin-min-vertivcal">{translate('Reconnecting...')}</div>}
            </h4>
            {!connectionCanBeRestored && (
              <button type="submit" className="btn btn-sm btn-primary margin-right" onClick={windowLocationReload}>
                {translate('Reload page')}
              </button>
            )}
            {(localHostnames.includes(window.location.hostname) ||
              window.location.hostname.includes('mylocal.com:')) && (
              <button
                type="button"
                className="btn btn-sm btn-warning margin-right"
                onClick={() => this.setState({ disconnected: false })}
              >
                {translate('Cancel')}
              </button>
            )}
          </div>
        )}
        <div>
          {renderRoutes(route.routes, {
            key: 'root-children',
            user,
            settings,
            mobileMode,
            router,
            routeParams: router.routeParams,
            socket,
            dispatchSignOut,
          })}
        </div>
      </GenericContext.Provider>
    );
  }
}

// @ts-expect-error
App.childContextTypes = {
  settings: PropTypes.object,
  user: PropTypes.object,
  getUserProfile: PropTypes.func,
  socket: PropTypes.object,
  getSectionsOptions: PropTypes.func.isRequired,
  setTopContainerRenderer: PropTypes.func.isRequired,
  isAllowedOperation: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  loadCustomComponents: PropTypes.func.isRequired,
};

// @ts-expect-error
App.propTypes = {
  user: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  chats: PropTypes.object,
  settings: PropTypes.object.isRequired,
  loadingSettings: PropTypes.bool.isRequired,
  loadSettingsError: PropTypes.string.isRequired,
  allowedOperationsNew: PropTypes.array.isRequired,
  signingOut: PropTypes.bool,
  children: PropTypes.node,
  location: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  dispatchLoadSettings: PropTypes.func.isRequired,
  dispatchLoadPermissions: PropTypes.func.isRequired,
  dispatchLoadUserProfile: PropTypes.func.isRequired,
  dispatchUpdateUserProfile: PropTypes.func.isRequired,
  // IO
  dispatchSignOut: PropTypes.func.isRequired,
};

const prepareGlobalSelector = (value: any) => prepareSelector('global', value);
const prepareMessengerSelector = (value: any) =>
  prepareSelector(window.location.pathname.includes('/candidate') ? 'candidate' : 'agent', value);
const mapStateToProps = createStructuredSelector({
  settings: prepareGlobalSelector('settings'),
  loadingSettings: prepareGlobalSelector('loadingSettings'),
  loadSettingsError: prepareGlobalSelector('loadSettingsError'),
  user: prepareGlobalSelector('user'),
  userProfile: prepareGlobalSelector('userProfile'),
  allowedOperationsNew: prepareGlobalSelector('allowedOperationsNew'),
  signingOut: prepareGlobalSelector('signingOut'),
  loadUserDetailsError: prepareMessengerSelector('loadUserDetailsError'),
  chats: prepareMessengerSelector('chats'),
});

const mapDispatchToProps = (dispatch: any) => ({
  // @ts-expect-error
  dispatchSignOut: () => dispatch(signOut()),
  // @ts-expect-error
  dispatchLoadSettings: (...args: any[]) => dispatch(loadSettings(...args)),
  // @ts-expect-error
  dispatchLoadPermissions: (...args: any[]) => dispatch(loadPermissions(...args)),
  dispatchLoadUserProfile: () => dispatch(loadUserProfile()),
  // @ts-expect-error
  dispatchUpdateUserProfile: (...args: any[]) => dispatch(updateUserProfile(...args)),
});

// eslint-disable-next-line import/no-default-export
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
