import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import compact from 'lodash/compact';
import { connect } from 'react-redux';

import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { requestBackend } from '@eva/emf/app/utils/request';
import { processValidationErrors, stringifyError } from 'shared/functions';
import { SpinnerSmall } from '@eva/emf/app/shared/ui/Spinner';

import {
  getProfilesSourcesDescriptions,
  prepareEducationItem,
  updateEducation,
} from 'containers/CandidatePaneNew/functions';
import { eventTypes } from 'containers/CandidatePaneNew/constants';

import { pluralize } from '../../shared/functions';
import { yearAndMonthToDate } from '../CandidatePaneNew/functions';

import FormEducation from './FormEducation';

const newItemKey = 'education-new';
const edItemKeys = ['schoolName', 'degreeName', 'description'];
const getInstitutionId = (institutionId) =>
  typeof institutionId === 'object' ? institutionId?.institutionId : institutionId;

const prepareValues = (item) => {
  const institutionId = getInstitutionId(item.institutionId);

  const values = edItemKeys.reduce(
    (prev, cur) => ({
      ...prev,
      [cur]: item[cur],
    }),
    {
      degreeTypeId: get(item, 'degreeType.degreeTypeId', null),
      startDate: yearAndMonthToDate(item.startDate),
      endDate: yearAndMonthToDate(item.endDate),
      institutionId,
    },
  );

  if (typeof institutionId === 'string') {
    // @ts-expect-error
    values.schoolName = institutionId;
    values.institutionId = null;
  } else if (!institutionId) {
    // @ts-expect-error
    values.schoolName = null;
    values.institutionId = null;
  }

  return values;
};

class CardEducation extends React.PureComponent<any, any> {
  // eslint-disable-next-line react/sort-comp
  state = {
    editModeRows: {},
    deleteModeRows: {},
    rowsErrors: {},
    profilesSourcesDescriptions: getProfilesSourcesDescriptions(),
  };
  unmounted: boolean;
  static contextTypes: { isAllowedOperation: PropTypes.Validator<(...args: any[]) => any> };
  extendPanel: any;

  UNSAFE_componentWillMount() {
    this.loadEducation(this.props, this.props.open);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { entity } = nextProps;

    if (this.props.entity !== entity) {
      // @ts-expect-error
      this.loadEducation(nextProps);
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  setItemEditMode = (educationId, editMode) => {
    const { editModeRows } = this.state;
    if (educationId === null) {
      return this.setState({
        newEducationItem: null,
      });
    }

    this.setState({
      editModeRows: {
        ...editModeRows,
        [educationId]: editMode,
      },
    });
  };

  loadEducation(props = this.props, open) {
    const {
      entity: { education },
    } = props;

    const fullHistory = get(education, 'degrees', []).reduce((prev, cur) => [...prev, prepareEducationItem(cur)], []);
    this.setState(
      {
        fullHistory,
        currentHistory: fullHistory,
        confirmingSection: false,
      },
      () => open && this.extendPanel(),
    );
  }

  saveEducationItem = async (preValues) => {
    const { updateEntity } = this.props;

    const { educationId, ...educationItem } = preValues.toJS();
    this.setState({ savingProfile: true });
    return await requestBackend(`/my/candidate-profile/education/${educationId || ''}`, {
      method: educationId ? 'PUT' : 'POST',
      body: JSON.stringify(prepareValues(educationItem)),
    }).then(
      (response) => {
        if (this.unmounted) {
          return;
        }
        const {
          entity: { education },
        } = this.props;
        this.setItemEditMode(educationId || null, false);
        const eventType = educationId ? eventTypes.educationUpdated : eventTypes.educationCreated;
        updateEntity({
          education: updateEducation(education, eventType, response),
        });
      },
      (err) => processValidationErrors(educationItem)(err),
    );
  };

  deleteEducationItem(educationId) {
    const { updateEntity } = this.props;
    const { deleteModeRows, rowsErrors } = this.state;

    this.setState({
      deleteModeRows: {
        ...deleteModeRows,
        [educationId]: true,
      },
      rowsErrors: {
        ...rowsErrors,
        [educationId]: '',
      },
    });

    requestBackend(`/my/candidate-profile/education/${educationId}`, {
      method: 'DELETE',
    }).then(
      () => {
        if (this.unmounted) {
          return;
        }
        const {
          entity: { education },
        } = this.props;
        updateEntity({
          education: updateEducation(education, eventTypes.educationDeleted, {
            educationId,
          }),
        });
      },
      (err) => {
        if (this.unmounted) {
          return;
        }
        this.setState({
          deleteModeRows: {
            ...deleteModeRows,
            [educationId]: false,
          },
          rowsErrors: {
            ...this.state.rowsErrors,
            [educationId]: stringifyError(err),
          },
        });
      },
    );
  }

  renderInstitutionTitle = (institution) => {
    if (!institution) {
      return null;
    }

    const swap = institution.shortTitle?.length > institution.title?.length;

    let shortTitle = swap ? institution.title : institution.shortTitle;
    const title = swap ? institution.shortTitle : institution.title;

    shortTitle = shortTitle?.trim().replace(/^\(/, '').replace(/\)$/, '');

    return (
      <>
        <p className="margin-min-vertical text-black">{title}</p>
        <p className="margin-min-vertical">{shortTitle}</p>
      </>
    );
  };

  renderInstitutionInfo = (institution) => {
    const address = compact([institution.city, institution.province, institution.country]).join(', ').trim();

    return (
      <div className="media margin-min-vertical">
        {institution?.logo && (
          <div className="media-left">
            <img className="institution-logo" src={institution?.logo} role="presentation" />
          </div>
        )}
        <div className="media-body">
          {address && (
            <p className="margin-min-vertical">
              <i className="lnr lnr-map-marker margin-right text-muted" />
              {address}
            </p>
          )}
          {institution.webSite && (
            <p className="margin-min-vertical">
              &nbsp;
              <i className="fa fa-external-link text-muted margin-right" />
              <a target="_blank" rel="noreferrer" href={institution.webSite}>
                {institution.webSite}
              </a>
            </p>
          )}
        </div>
        <div className="clearfix" />
      </div>
    );
  };

  renderNewItem = () => {
    const { newEducationItem } = this.state as any;
    const { editOptions } = this.props;
    return newEducationItem ? (
      <div key={newItemKey}>
        <div>
          <FormEducation
            form={newItemKey}
            initialValues={newEducationItem}
            // @ts-expect-error
            editOptions={editOptions}
            onSubmit={this.saveEducationItem}
            setItemEditMode={this.setItemEditMode}
          />
        </div>
      </div>
    ) : null;
  };

  renderItem = (item, index) => {
    const { isAllowedOperation } = this.context;
    const { editOptions } = this.props;
    const { editModeRows, deleteModeRows, rowsErrors } = this.state;

    const description = item.description || '';

    if (editModeRows[item.educationId]) {
      const key = `education-${item.educationId}`;
      return (
        <div key={key}>
          <div>
            <FormEducation
              form={key}
              initialValues={item.initialValues}
              // @ts-expect-error
              editOptions={editOptions}
              onSubmit={this.saveEducationItem}
              setItemEditMode={this.setItemEditMode}
            />
          </div>
        </div>
      );
    }

    return (
      <div key={`education-${index}`} className="education-item">
        {rowsErrors[item.educationId] && <div className="text-danger">{rowsErrors[item.educationId]}</div>}
        <div className="row">
          <div className="col-xs-8">
            <p className="text-black">{item.degreeName}</p>
            {item.degreeType && <p className="margin-min-vertical">{item.degreeType.name}</p>}
            {item.institution ? (
              this.renderInstitutionTitle(item.institution)
            ) : (
              <p className="margin-min-vertical text-black">{item.schoolName}</p>
            )}
          </div>
          <div className="col-xs-4 text-right text-nowrap">
            <div>
              {isAllowedOperation('myProfile-education-update') && (
                <PopperTooltip overlay={translate('Edit')}>
                  <button
                    id="edit-education"
                    type="button"
                    className="btn-box-tool btn btn-sm pencil-edit-btn no-padding"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      this.setItemEditMode(item.educationId, true);
                    }}
                  >
                    <i className="lnr lnr-pencil" />
                  </button>
                </PopperTooltip>
              )}
              {isAllowedOperation('myProfile-education-delete') && (
                <PopperTooltip overlay={translate('Delete')}>
                  <button
                    type="button"
                    id={`education-delete-icon-${index}`}
                    className="btn-box-tool btn btn-sm margin-left no-padding"
                    onClick={() => this.deleteEducationItem(item.educationId)}
                  >
                    {deleteModeRows[item.educationId] && <SpinnerSmall />}
                    {!deleteModeRows[item.educationId] && <i className="lnr lnr-trash text-danger" />}
                  </button>
                </PopperTooltip>
              )}
            </div>
            <div>
              {item.period != null && (
                <span id={`education-item-period-${index}`}>
                  <p>
                    {item.period.label && (
                      <span>
                        <i className="lnr lnr-calendar-full text-muted" />
                        &nbsp;
                        <span className="text-black">{item.period.label}</span>
                      </span>
                    )}
                  </p>
                  <p className="margin-min-vertical">
                    {item.period.years
                      ? `${item.period.years} ${translate(pluralize(item.period.years, 'year'))} `
                      : ''}
                    {item.period.months
                      ? `${item.period.months} ${translate(pluralize(item.period.months, 'month'))}`
                      : ''}
                  </p>
                </span>
              )}
            </div>
            <p className="margin-min-vertical">{item.periodLength ? `${item.periodLength} year(s)` : ''}</p>
          </div>
        </div>
        {item.institution && (
          <div id={`education-item-address-${index}`}>{this.renderInstitutionInfo(item.institution)}</div>
        )}
        <div className="row margin-bottom" id={`education-item-description-${index}`}>
          <div className="col-xs-12 margin-min-vertical list-format">
            <span dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </div>
        <div className="clearfix" />
      </div>
    );
  };

  render() {
    const { isAllowedOperation } = this.context;
    const {
      entity: { education },
    } = this.props;
    const { currentHistory, saving, error } = this.state as any;

    if (saving) {
      return (
        <div>
          <p>{translate('Saving work history')}...</p>
        </div>
      );
    } else if ('plainText' in (education || {})) {
      return <div>{education.plainText}</div>;
    }

    const header = (
      <div onClick={(evt) => evt.stopPropagation()}>
        {translate('Education')}
        {isAllowedOperation('myProfile-education-create') && (
          <span className="pull-right">
            <PopperTooltip overlay={translate('Add')}>
              <button
                type="button"
                id="add-education-item"
                className="btn btn-box-tool btn-sm no-padding text-success check-save-btn"
                onClick={(evt) => {
                  evt.stopPropagation();
                  this.setState({
                    newEducationItem: {
                      startDate: {
                        month: '',
                        year: '',
                      },
                      endDate: {
                        month: '',
                        year: '',
                      },
                      schoolName: '',
                      degreeName: '',
                      degreeTypeId: 0,
                      description: '',
                    },
                  });
                }}
              >
                <i className="lnr lnr-plus-circle text-success" />
              </button>
            </PopperTooltip>
          </span>
        )}
      </div>
    );

    return (
      <div id="education-panel">
        {error && <p className="text-danger">{error}</p>}
        <div>
          <h3 className="text-primary">{header}</h3>
        </div>
        {this.renderNewItem()}
        {currentHistory.map(this.renderItem)}
        {!currentHistory.length && <div id="education-empty-history">{translate('No education history recorded')}</div>}
      </div>
    );
  }
}

CardEducation.contextTypes = {
  isAllowedOperation: PropTypes.func.isRequired,
};

// @ts-expect-error
CardEducation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  entity: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
  editOptions: PropTypes.object.isRequired,
  open: PropTypes.bool,
};

// eslint-disable-next-line import/no-default-export
export default connect()(CardEducation);
