import React, { useCallback, useLayoutEffect, useState } from 'react';

import { ModalSkillTaxonomyWidget } from './ModalSkillTaxonomyWidget';

type Props = {
  attributes: Record<string, unknown>;
  editable: boolean;
  resultingContent: () => void;
  delayScrollDown?: () => void;
  skillTaxonomyId: number;
};

export const SkillTaxonomyWidget: React.FC<Props> = ({ editable, resultingContent, delayScrollDown, attributes }) => {
  const skillTaxonomyId = +attributes.skilltaxonomyid;

  const [showModal, setShowModal] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleSkip = useCallback(() => {
    setSubmitted(true);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resultingContent(translate('Skip'));
  }, [resultingContent]);

  const handleOpenModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleHideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  useLayoutEffect(() => {
    if (typeof delayScrollDown === 'function') {
      delayScrollDown();
    }
  });

  if (!editable || submitted) {
    return null;
  }

  return (
    <div style={{ display: 'inline' }}>
      <ModalSkillTaxonomyWidget show={showModal} onHide={handleHideModal} skillTaxonomyId={skillTaxonomyId} />
      <button type="button" className={'bubble me'} disabled={submitted || !editable} onClick={handleOpenModal}>
        <span className="bubble-content">
          <span className="message-content">{translate('Select skills')}</span>
        </span>
      </button>
      <button type="button" className={'bubble me'} disabled={submitted || !editable} onClick={handleSkip}>
        <span className="bubble-content">
          <span className="message-content">{translate('Skip')}</span>
        </span>
      </button>
    </div>
  );
};
