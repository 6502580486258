import { Component, ReactNode } from 'react';

import { getQueryVariables } from 'shared/functions';

export interface SectionDescriptor {
  Preview: Component;
  icon: string;
  label: ReactNode;
  paneSettings: any[];
  sectionKey: string;
}

export const getSelectedSection = (sections: SectionDescriptor[]) => {
  const query = getQueryVariables() as any;

  const sectionFromQuery = query.section;

  if (!sectionFromQuery) {
    return undefined;
  }

  return sections.find((section) => section.sectionKey === sectionFromQuery);
};
