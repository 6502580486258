import { toast } from 'react-toastify';

import { getQueryVariables } from '@eva/emf/app/shared/functions';
import type { WorkflowDto } from '@eva/types/dto';
import type { ListedResponse } from '@eva/emf/app/types/common-types';
import { cacheNames, toastContainerId } from 'shared/constants';
import { JobDto } from '@eva/types/dto';

export const showApplyToJobFailed = () => {
  const icon = <i className="lnr lnr-bug" />;

  const element = (
    <div className="text-left">
      <span className="notification-icon pull-left margin-right">
        {icon}
        <i className="ll-icon ll-logo-eva-small fa-2x text-primary" />
      </span>
      <h4 className="margin-left pull-left text-danger">{translate('Error!')}</h4>
      <div className="clearfix" />
      <p>{translate('An error occurred.')}</p>
    </div>
  );

  return toast(element, {
    delay: 100,
    containerId: toastContainerId,
  });
};

export const showApplyToJobSuccess = (workflowId: number, job: Partial<JobDto>) => {
  const icon = <i className="lnr lnr-bullhorn" />;

  const element = (
    <div className="text-left">
      <span className="notification-icon pull-left margin-right">
        {icon}
        <i className="ll-icon ll-logo-eva-small fa-2x text-primary" />
      </span>
      <h4 className="margin-left pull-left text-success">{translate('Success!')}</h4>
      <div className="clearfix" />
      <p>
        {translate('Click here to review your application:')}{' '}
        <a
          key={job.jobId}
          className="job-recommendation-item"
          href={`/candidate/my-jobs?workflowId=${workflowId}&jobId=${job.jobId}`}
          rel="noreferrer"
        >
          {job.title}
        </a>
      </p>
    </div>
  );

  return toast(element, {
    delay: 100,
    containerId: toastContainerId,
  });
};

export const getActiveJobWorkFlowId = (jobsWorkflows: WorkflowDto[]) => {
  const query = getQueryVariables(null);

  const jobWorkFlowIdFromQuery = Number(query.workflowId);

  const jobWorkFlowFromQuery = jobsWorkflows.find(({ workflowId }) => workflowId === jobWorkFlowIdFromQuery);

  return jobWorkFlowFromQuery?.workflowId ?? jobsWorkflows[0].workflowId;
};

export const buildCacheName = (workflowId: number) => `${cacheNames.candidateJobs}-workflow-${workflowId}`;

export const appendExistingIds = <T,>(
  items: T[],
  existingIds: { [key: number]: number },
  getId: (entry: T) => number,
): { [key: number]: number } =>
  items?.reduce?.((acc, entry) => {
    const id = getId(entry);
    acc[id] = 1;
    return acc;
  }, existingIds || {}) || {};

export const filterPage = <T,>(pageToFilter: ListedResponse<T>, cb: (item: T) => boolean): ListedResponse<T> => {
  if (!pageToFilter) {
    return pageToFilter;
  }

  const items = pageToFilter.items?.filter?.(cb);

  return {
    items,
    total: pageToFilter.total,
  };
};

export const getNextJob = (jobs: JobDto[], currentJobdId: number) => {
  if (!jobs?.length) {
    return null;
  }

  if (jobs.length === 1) {
    return jobs[0].jobId === currentJobdId ? null : jobs[0];
  }

  const currentJobIndex = jobs.findIndex(({ jobId }) => currentJobdId === jobId);

  const nextJobIndex = currentJobIndex === jobs.length - 1 ? currentJobIndex - 1 : currentJobIndex + 1;

  return jobs[nextJobIndex];
};

export const getNextJobId = (jobs: JobDto[], currentJobdId: number) => {
  const nextJob = getNextJob(jobs, currentJobdId);
  return nextJob?.jobId;
};
