/* eslint-disable no-restricted-properties */
import PropTypes from 'prop-types';
import React from 'react';
import ms from 'ms';
import get from 'lodash/get';
import moment from 'moment';
import 'whatwg-fetch';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { request, requestBackend } from '@eva/emf/app/utils/request';
import { baseEndpoint } from '@eva/emf/app/shared/constants';
import authService from '@eva/emf/app/services/auth';
import { logWarning, removeScriptTags, windowLocationReload } from '@eva/emf/app/shared/functions';
import {
  userTypes,
  aiNodes,
  leftMenuModes,
  messagesTypes,
  messengerQueryParams,
  userAccountTypes,
} from 'shared/constants';
import {
  getConversationId,
  getQueryVariables,
  guid,
  onTokenFailed,
  prepareSelector,
  replaceCompanyName,
  temporaryUser,
  setQueryVariables,
  wipeStorage,
  getTokenPayload,
  redirectTo,
  replaceUrl,
  logError,
} from 'shared/functions';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';

import MessengerLeftMenu from 'containers/MessengerLeftMenu';
import MessengerChat from 'containers/MessengerChat';
import MessengerChatHeader from 'containers/MessengerChatHeader';
import MessengerMobileHeader from 'containers/MessengerMobileHeader';
import { signInSuccess, signOut, signUp } from 'containers/App/actions';
import { mainMenuKeys } from 'containers/MessengerLeftMenu/constants';
import { getMenuKey } from 'containers/MessengerLeftMenu/functions';

import { loadPermissions } from '../App/actions';

import {
  sendMessage,
  onChangeStatus,
  chatSelected,
  loadCandidatesFilter,
  loadChats,
  loadMessages,
  loadPipelines,
  loadUserDetails,
  deleteCandidate,
  markLocalRead,
  onNewMessage,
  onPipelineUpdated,
  onProfileUpdated,
  onUpdatingProfile,
  searchCandidates,
  onCandidateProfileUpdated,
  onConversationWorkflowChanged,
  onOmnichatUpdated,
  onCandidateRegistrationCompleted,
} from './actions';
import { ModalSystemError } from './ModalSystemError';
import {
  validateCandidateUpdatedEvent,
  validateNewMessageEvent,
  validatePipelineUpdatedEvent,
  validateCandidateRegistrationCompletedEvent,
  validateConversationWorkflowStateChangedEvent,
  withValidation,
  validateOmnichatUpdatedEvent,
  validateChangeStatusEvent,
} from './event-validators';

import './style.css';

const granted = 'granted';
const fakeMessage = (content, agentSide = false, options = {}) => ({
  messageId: guid(),
  sentOn: moment(),
  readOn: moment(),
  sender: agentSide
    ? {
        userId: 8,
        name: {
          displayName: 'Eva',
        },
      }
    : {
        name: {
          displayName: '',
        },
      },
  messageType: {
    messageTypeId: messagesTypes.text,
  },
  ai: agentSide,
  content,
  agentSide,
  fake: true,
  accountType: userAccountTypes.candidate,
  options,
});
const fakeConversationId = Math.floor((1 + Math.random()) * 0x1000000000);
const emailTag = '<email';
const eventTypes = {
  webchatMessageSent: 'webchat-message-sent',
  omnichatMessageSent: 'omnichat-message-sent',
  onlineStatus: 'online-status',
  candidateUpdated: 'candidate-updated',
  candidateRegistrationCompleted: 'candidate-registration-completed',
  candidatePipelineUpdated: 'candidate-pipeline-updated',
  conversationWorkflowStateChanged: 'conversation-workflow-state-changed',
  omnichatUpdated: 'omnichat-updated',
  systemError: 'system-error',
};

class MessengerCandidate extends React.Component<any, any> {
  props: any;
  unmounted: any;
  modalSystemError: any;
  visibilityChangeHandler: any;
  candidateProfileSections: any;
  eventHandlers: any;

  state: any = {
    activeChat: 0,
    chatsArray: [],
    editOptions: {},
    showRightPane: false,
    notifications: false,
    uiReady: this.props?.chats.length,
    messages: [],
    conversationOver: false,
    forceEmail: '',
    suppressWarmingUp: false,
    signingIn: false,
    candidatePaneButtonsProps: {},
    centralContent: null,
    mobileActiveMenuKey: '',
    activeMenuKey: mainMenuKeys.chat,
  };

  async UNSAFE_componentWillMount() {
    const { getSectionsOptions } = this.context as { getSectionsOptions: () => any };
    const { user, socket, router, dispatchSignInSuccess, dispatchLoadChats, dispatchLoadPipelines } = this.props;

    const query: any = getQueryVariables();

    const isCandidate = true;
    if (socket) {
      this.onConnectSocket();
    }

    if (query.token) {
      try {
        const profile: any = await requestBackend('/authentication/my/profile');
        const authTypes: any = profile.authMethods ? Object.keys(profile.authMethods) : [];

        const decodedUser: any = getTokenPayload(query.token);
        authService.signIn({
          user: decodedUser,
          token: query.token,
          authTypes,
        });
      } catch (err) {
        if (err.payload.error) {
          this.showSystemError(err.payload.error);
        }
      }
    }

    if (isCandidate && user.userId) {
      dispatchLoadChats();
      dispatchLoadPipelines();
    }

    if (user.accountTypes && !user.accountTypes.includes(userAccountTypes.candidate)) {
      wipeStorage();
      return windowLocationReload();
    }

    if (query.invite) {
      this.setState({ invite: true });

      request(`${baseEndpoint}/api/authentication/auth/login?token=${encodeURIComponent(query.invite)}`, {
        method: 'GET',
      }).then((payload: any) => {
        payload.user.userType = payload.user.userType || userTypes.candidate;
        const wrongAccountType = payload.user.accountTypes.includes(userAccountTypes.candidate)
          ? 0
          : payload.user.accountTypes;

        if (!wrongAccountType) {
          dispatchSignInSuccess({
            ...payload,
            wrongAccountType,
          });

          const newQuery = { ...query };
          delete newQuery.invite;

          redirectTo({
            pathname: query.redirect || window.location.pathname,
            query: newQuery,
          });

          if (!this.unmounted) {
            this.setState({ invite: false });
          }
        }
      }, onTokenFailed);
    }

    this.userLoadedCheck(this.props);
    this.checkSignUpQuery(query, user, router, 'candidate');

    if ('Notification' in window) {
      if (Notification.permission === granted) {
        this.setState({ notifications: true });
      } else if (Notification.permission !== 'denied') {
        const notificationsDenied =
          localStorage.notificationsDenied && Date.now() - parseInt(localStorage.notificationsDenied, 0) < ms('1w');
        if (Notification.requestPermission && !notificationsDenied) {
          Notification.requestPermission((permission) => {
            if (permission === granted) {
              this.setState({ notifications: true });
            } else {
              localStorage.notificationsDenied = Date.now();
            }
          });
        }
      }
    }

    setQueryVariables({
      entityModal: undefined,
    });

    this.candidateProfileSections = getSectionsOptions();
  }

  componentDidMount() {
    this.visibilityChangeHandler = this.visibilityChanged.bind(this);

    window.addEventListener('focus', this.visibilityChangeHandler, false);
    window.addEventListener('blur', this.visibilityChangeHandler, false);
    window.focus();
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { user, socket, chats, dispatchLoadChats, dispatchLoadPipelines, router } = nextProps;
    const { candidatePaneButtonsProps, activeChat, createdCandidateId, activeMenuKey } = this.state;

    const query: any = getQueryVariables();
    const isCandidate = true;
    const activeChatChanged = this.props.chats[activeChat] !== chats[activeChat];

    if (!this.props.socket && socket) {
      this.onConnectSocket(nextProps);
    } else if (this.props.socket && !socket) {
      this.disconnectSocket();
    }

    if (this.props.user.userId && this.props.user.userId !== user.userId) {
      return windowLocationReload();
    } else if (isCandidate && !this.props.user.userId && user.userId) {
      dispatchLoadChats();
      dispatchLoadPipelines();
    }

    if (this.props.user.userId && query.userId) {
      this.checkSignUpQuery(query, user, router, 'candidate');
    }

    const menuKey = getMenuKey();

    if (menuKey && menuKey !== mainMenuKeys.chat) {
      this.setState({
        mobileActiveMenuKey: menuKey,
      });
    }

    if (activeMenuKey !== menuKey) {
      this.setState({
        activeMenuKey: menuKey,
      });
    }

    this.userLoadedCheck(nextProps);

    if (this.props.messages !== nextProps.messages || this.props.user.userId !== user.userId) {
      const messages = nextProps.messages[this.state.activeChat] || [];
      if (messages.length || !this.state.suppressWarmingUp) {
        this.setState({ messages });
      }

      if (messages.length && nextProps.user.userType === userTypes.candidate) {
        const lastMessageContent = messages[messages.length - 1].content || '';

        if (lastMessageContent.includes('<over />')) {
          document.body.style.filter = 'grayscale(1)';
          this.setState({ conversationOver: true });
        } else {
          const emailMessage = messages.find((message) => message.ai && (message.content || '').includes(emailTag));
          if (emailMessage) {
            const { content } = emailMessage;
            const emailIndex = content.indexOf(emailTag);
            this.setState({
              forceEmail: content
                .substr(emailIndex + emailTag.length)
                .replace('/>', '')
                .trim(),
            });
          }
          const resetTokens = messages.find(
            (message: any) => message.ai && (message.content || '').includes('resetTokens'),
          );
          if (resetTokens) {
            wipeStorage();
          }
        }
      } else if (this.state.forceEmail) {
        this.setState({ forceEmail: '' });
      }
    }

    const chatsChanged = this.props.chats !== chats;

    if (chatsChanged) {
      if (createdCandidateId) {
        this.checkIfNewlyCreated(nextProps);
      }
      if (Object.keys(this.props.chats).length && !Object.keys(chats).length) {
        this.setState({ activeChat: 0 });
      }
    }

    if (chatsChanged || (!this.props.socket && socket)) {
      // Detect if we need to join rooms
      const nextChats = Object.keys(chats);
      const chatsArray: any = Object.values(chats).sort((first: any, next: any) => {
        if (first.recentMode || next.recentMode) {
          if (!next.sentOn) {
            return -1;
          }
          return next.sentOn.diff(first.sentOn, 's');
        }

        return first.serverSorting - next.serverSorting;
      });

      this.setState({ chatsArray });

      if (socket) {
        if (!activeChat && nextChats.length) {
          if (chatsArray[0].conversationId) {
            const initRoom = parseInt(query.room, 0);
            const initActiveChat =
              query.room && chatsArray.find((item: any) => item.conversationId === initRoom)
                ? initRoom
                : chatsArray[0].conversationId;

            if (query.room) {
              const locationQuery = { ...query };
              delete locationQuery.room;
              replaceUrl({
                pathname: window.location.pathname,
                query: locationQuery,
              });
            }

            this.setActiveChat(nextProps, initActiveChat);
          } else {
            logError(chatsArray[0]);
            throw new Error('Not able to get chats - missing conversation ID!');
          }
        }
      }

      this.setState({ uiReady: true });

      if (activeChat && !chats[activeChat] && chatsArray[0]) {
        this.setActiveChat(nextProps, chatsArray[0].conversationId);
      } else if (activeChatChanged) {
        this.setState({ chat: chats[activeChat] });
      }
    }

    if (!this.props.user.userId && user.userId) {
      this.setState({ activeChat: null });
    }

    if (activeChatChanged) {
      this.setState({
        candidatePaneButtonsProps: {
          ...candidatePaneButtonsProps,
          chat: chats[activeChat],
        },
      });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const { user, messages, dispatchMarkLocalRead, chatsLoadError } = this.props;
    const { activeChat } = this.state;

    const query: any = getQueryVariables();

    // Mark as read
    const prevMessages = prevProps.messages[activeChat];
    const newMessages = messages[activeChat];

    // Active chat switched and new chat have loaded messages
    const checkChat = newMessages && activeChat !== prevState.activeChat;

    // Messages loaded for chat or new message
    const checkMessages = prevMessages && newMessages && prevMessages.length !== newMessages.length;

    if (chatsLoadError) {
      this.showSystemError(chatsLoadError);
    }

    if (checkChat || checkMessages) {
      const unread = newMessages
        .filter((message: any) => !message.readOn && message.sender.userId !== user.userId)
        .map((message: any) => message.messageId || message.omnichatMessageId);
      if (unread.length) {
        requestBackend('/webchats/read', {
          method: 'POST',
        });
      }
    }

    // Mark previous chat as read
    if (user.userId && prevState.activeChat && prevState.activeChat !== activeChat) {
      dispatchMarkLocalRead(prevState.activeChat);
    }

    if (!prevState.activeChat && this.state.activeChat) {
      if (query.pj) {
        const { pj, ...updatedQuery } = query;

        this.emitSendMessage(
          translate('{{ prefix }}I would like to discuss a job I have applied for:', {
            prefix: `/postjob ${pj}#`,
          }),
        );
        replaceUrl({
          pathname: window.location.pathname,
          query: updatedQuery,
        });
      }
    }
  }

  componentWillUnmount() {
    this.unmounted = true;
    window.removeEventListener('focus', this.visibilityChangeHandler);
    window.removeEventListener('blur', this.visibilityChangeHandler);
    this.disconnectSocket();
  }

  onConnectSocket(props = this.props) {
    const { socket, dispatchSearchCandidates } = props;

    const query: any = getQueryVariables();

    if (query.user) {
      dispatchSearchCandidates({ name: query.user });
    }
    this.connectChatEvents(socket);
  }

  getHeaderTitle() {
    const { settings } = this.props;

    const menuKey = getMenuKey();

    const { section, staticPageId }: any = getQueryVariables();

    if (menuKey === mainMenuKeys.myProfile) {
      if (section) {
        const candidateProfileSection = this.candidateProfileSections.find(({ sectionKey }) => sectionKey === section);
        if (candidateProfileSection) {
          return candidateProfileSection.label;
        }
      }

      return translate('My profile');
    }

    if (menuKey === mainMenuKeys.home) {
      if (staticPageId) {
        const staticPage = settings.staticPages.find((item: any) => item.staticPageId === parseInt(staticPageId));
        return staticPage?.name || translate('Home');
      }

      return translate('Home');
    }

    if (menuKey === mainMenuKeys.myJobs) {
      return translate('My jobs');
    }

    if (menuKey === mainMenuKeys.recommended) {
      return translate('Recommended');
    }

    return translate('Chat with an agent');
  }

  setActiveChat = (props = this.props, activeChat: any) => {
    const { settings, socket, messages, loadingMessages, dispatchLoadMessages, dispatchChatSelected } = props;
    const { suppressWarmingUp } = this.state;
    if (!loadingMessages[activeChat]) {
      dispatchLoadMessages(activeChat, undefined, settings.general.companyName);
    }
    dispatchChatSelected(activeChat);

    let chat: any;
    let candidatePaneButtonsProps = {};
    if (socket) {
      chat = props.chats[activeChat];
      candidatePaneButtonsProps = {
        socket,
        chat,
      };
    }

    this.setState({
      chat,
      activeChat,
      candidatePaneButtonsProps,
    });

    if (!suppressWarmingUp) {
      this.setState({ messages: messages[activeChat] || [] });
    }
  };

  setCentralContent = (centralContent: any) => {
    this.setState(
      {
        centralContent,
      },
      () => {
        const jobCentralPanelElements = document.getElementsByClassName('job-central-panel');
        if (jobCentralPanelElements[0]) {
          jobCentralPanelElements[0].scrollTop = 0;
        }
      },
    );
  };

  setCreateCandidateMode = () => {
    this.setState({
      createCandidateMode: true,
      createdCandidateId: null,
    });
  };

  checkSignUpQuery(query: any, user: any, router: any, accountType: any) {
    const signUpFlag =
      query.userId && query.token && ['undefined', (user.userId || '').toString()].includes(query.userId.toString());

    if (signUpFlag) {
      const newQuery = { ...query };
      ['up', 'userId', 'email', 'token', 'firstName', 'lastName', 'userType', 'redirect', 'hash'].forEach(
        (value) => delete newQuery[value],
      );

      const splittedRedirect = (query.redirect || '').split('?');

      if (splittedRedirect[1]) {
        splittedRedirect[1].split('&').forEach((valuesPair) => {
          const pair = valuesPair.split('=');
          newQuery[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]) || '';
        });
      }

      redirectTo({
        pathname: splittedRedirect[0] || `/${accountType}`,
        query: newQuery,
      });
    } else {
      const queryVariables = getQueryVariables();
      const queryVariablesKeys = Object.keys(queryVariables);

      setQueryVariables({
        ...queryVariablesKeys.reduce(
          (prev, cur) => ({
            ...prev,
            [cur]: messengerQueryParams.includes(cur) ? queryVariables[cur] : undefined,
          }),
          {},
        ),
      });
    }
  }

  disconnectSocket() {
    const { socket } = this.props;

    if (socket) {
      Object.values(eventTypes).forEach((eventType) => socket.removeListener(eventType, this.eventHandlers[eventType]));
    }
  }

  visibilityChanged(evt: any) {
    this.setState({ chatHidden: evt.type === 'blur' });
  }

  userLoadedCheck(props: any) {
    const {
      settings: {
        evaGreetings,
        companyName,
        general: { companyName: agencyName },
      },
      user,
      userDetails,
      dispatchLoadUserDetails,
      loadingUserDetails,
    } = props;

    if (!user.userId && evaGreetings) {
      this.setState({
        chat: {
          userId: 8,
        },
        activeChat: fakeConversationId,
      });

      ['Initial', 'signInConfirm'].forEach((eventCategory) =>
        window.sendGAEvent(eventCategory, 'click', 'EvaChat-1-Initial'),
      );
      const companyNameReplacer = replaceCompanyName(companyName || agencyName);

      setTimeout(() => {
        if (this.unmounted) {
          return;
        }

        this.setState({
          messages: [fakeMessage(evaGreetings[0], true), fakeMessage(evaGreetings[1], true)].map(companyNameReplacer),
        });
      }, 200);
    } else if (!loadingUserDetails && !userDetails.userId && user.userId) {
      dispatchLoadUserDetails();
    }
  }

  searchCandidates = (query: any, offset: any) => {
    const { dispatchSearchCandidates } = this.props;
    dispatchSearchCandidates(query, offset);

    this.setState({
      candidatePaneParams: {
        jobId: query.areaType === 'j' ? query.areaValue : '',
      },
    });
  };

  showSystemError = (error: any) => {
    this.modalSystemError.open(error);
  };

  emitSendMessage = (initialContent, file?: any, fileUrl?: any, messageMode?: any) => {
    let content = typeof initialContent === 'string' ? initialContent : `${initialContent}`;

    content = removeScriptTags(content);

    const {
      user,
      userProfile,
      settings: { evaGreetings },
      socket,
      chats,
      dispatchSendMessage,
    } = this.props;
    const { activeChat, messages } = this.state;
    // Fake AI
    if (!user.userId) {
      messages.push(fakeMessage(content, false));
      const greeting = evaGreetings[2][content];
      if (!greeting) {
        throw new Error('No greeting found. Check the configuration of prepared greetings in Eva Greetings Editor.');
      }
      const { createUser, text } = greeting;
      if (createUser) {
        this.createNewCandidate();
        this.setState({ suppressWarmingUp: true });
      }
      setTimeout(() => {
        messages.push(fakeMessage(text, true));
        window.sendGAEvent(createUser ? 'signInNotPossible' : 'signInProceed', 'click', 'EvaChat-1-Initial');
        return this.setState({ messages: [...messages] });
      });
      return this.setState({ messages: [...messages] });
    }

    const chatsActiveChat = chats[activeChat];
    if (!chatsActiveChat || typeof chatsActiveChat !== 'object') {
      logError(chats);
      logError(activeChat);
      logError('chatsActiveChat not found');
    }

    const { conversationId } = chatsActiveChat;
    const randomId = guid();

    const attachments = file
      ? [
          {
            name: file.name,
            url: fileUrl,
          },
        ]
      : [];

    const payload = {
      conversationId,
      message: {
        randomId,
        messageId: randomId,
        content,
        sentOn: moment(),
        attachments,
        sender: {
          userId: user.userId,
          name: {
            displayName: userProfile?.displayName || '',
          },
        },
        sender_id: user.userId, // eslint-disable-line camelcase
        messageMode,
        accountType: userAccountTypes.candidate,
      },
    };

    socket.emit('send-webchat-message', payload);
    dispatchSendMessage(conversationId, payload);
  };

  createNewCandidate() {
    this.props.dispatchSignUp({
      firstName: 'New',
      lastName: 'candidate',
      email: `${guid()}@new-candidate.org`,
      password: guid(),
      file: null,
      accountType: 'candidate',
    });
  }

  showNotification(message: any) {
    const { settings } = this.props;
    const { notifications, chatHidden } = this.state;

    const sender = get(message, 'sender.name.displayName');

    if (notifications && chatHidden && !message.error) {
      const notificationLabel = translate('New message {{ senderText }}', {
        senderText: sender ? `from ${sender}` : '',
      });
      new Notification(notificationLabel, {
        // eslint-disable-line no-new
        body: message.isJob ? translate('Job proposal') : message.content,
        icon: settings.logos.smallLogo,
      });
    }
  }

  connectChatEvents(socket: any) {
    const {
      dispatchNewMessage,
      dispatchChatSelected,
      dispatchPipelineUpdated,
      dispatchChangeStatus,
      dispatchCandidateProfileUpdated,
      dispatchConversationWorkflowChanged,
      dispatchOmnichatUpdated,
      dispatchCandidateRegistrationCompleted,
      dispatchLoadPermissions,
    } = this.props;

    const onNewMessageEvent = (payload: any) => {
      if (this.unmounted) {
        return;
      }

      const { message = {}, userType } = payload;

      const { user, chats, mobileMode } = this.props;

      const { activeChat } = this.state;

      if (typeof userType === 'number' && userType !== userTypes.candidate) {
        return;
      }

      const conversationId = getConversationId(payload);
      const chat = chats[conversationId];

      if (!chat) {
        logWarning(`Failed to find chat`, { conversationId });
        return;
      }

      const myMessage = get(message, 'sender.userId') === this.props.user.userId;
      if (!myMessage) {
        this.showNotification(message);
      }

      dispatchNewMessage(conversationId, payload);
      if (conversationId === activeChat) {
        dispatchChatSelected(activeChat);
        if (get(message, 'sender.userId') !== user.userId) {
          socket.emit('webchat-message-read', {
            conversationId: activeChat,
          });
        }
      }

      // Google Analytics
      // @ts-expect-error
      if (message.node === aiNodes.cv) {
        window.sendGAEvent('CV', 'click', 'EvaChat-2-CollectingData');
        // @ts-expect-error
      } else if (message.node === aiNodes.tnc) {
        window.sendGAEvent('tnc', 'click', 'EvaChat-2-CollectingData');
        // @ts-expect-error
      } else if (message.node === aiNodes.allGood) {
        window.sendGAEvent('allGood', 'click', 'EvaChat-3-Finishing');
        // @ts-expect-error
      } else if (message.node === aiNodes.finish) {
        window.sendGAEvent('finish', 'click', 'EvaChat-3-Finishing');
        if (mobileMode) {
          this.setState({ leftPane: leftMenuModes.signIn });
        }
      }
    };

    this.eventHandlers = {
      [eventTypes.webchatMessageSent]: withValidation(validateNewMessageEvent, onNewMessageEvent),
      [eventTypes.omnichatMessageSent]: withValidation(validateNewMessageEvent, onNewMessageEvent),
      [eventTypes.candidateUpdated]: withValidation(validateCandidateUpdatedEvent, ({ userId, ...updatedSections }) =>
        dispatchCandidateProfileUpdated(userId, updatedSections),
      ),
      [eventTypes.candidatePipelineUpdated]: withValidation(validatePipelineUpdatedEvent, (pipeline: any) =>
        dispatchPipelineUpdated(pipeline),
      ),
      [eventTypes.candidateRegistrationCompleted]: withValidation(validateCandidateRegistrationCompletedEvent, () => {
        dispatchLoadPermissions();
        dispatchCandidateRegistrationCompleted();
      }),
      [eventTypes.conversationWorkflowStateChanged]: withValidation(
        validateConversationWorkflowStateChangedEvent,
        ({ entityId, workflowState }) => dispatchConversationWorkflowChanged(entityId, workflowState),
      ),
      [eventTypes.omnichatUpdated]: withValidation(validateOmnichatUpdatedEvent, ({ omnichat }) =>
        dispatchOmnichatUpdated(omnichat),
      ),
      [eventTypes.onlineStatus]: withValidation(validateChangeStatusEvent, (payload: any) =>
        dispatchChangeStatus(payload),
      ),
      [eventTypes.systemError]: (error: any) => this.showSystemError(error),
    };

    Object.values(eventTypes).forEach((eventType) => socket.on(eventType, this.eventHandlers[eventType]));
  }

  checkIfNewlyCreated(props = this.props) {
    const { chats } = props;

    const { createdCandidateId } = this.state;

    const newlyCreated = Object.values(chats).find((item: any) => item.userId === createdCandidateId);

    if (newlyCreated) {
      this.setActiveChat(props, getConversationId(newlyCreated));
      this.setState({ createdCandidateId: null });
    }
  }

  updateCreatedCandidateId = (createdCandidateId: any) => {
    this.setState({ createdCandidateId }, () => this.checkIfNewlyCreated());
  };

  toggleLeft = (leftPaneValue: any) => {
    this.setState({
      leftPane: leftPaneValue,
      showJobs: true,
    });
  };

  cancelCreateCandidateMode = () => {
    this.setState({ createCandidateMode: false });
  };

  render() {
    const {
      user,
      settings,
      userDetails,
      socket,
      pipelines,
      loadingPipelines,
      pipelinesLoaded,
      loadingMessages,
      mobileMode,
      location,
      dispatchSignOut,
      dispatchLoadMessages,
      dispatchLoadPipelines,
      dispatchMarkLocalRead,
      userProfile,
    } = this.props;

    const {
      chat,
      messages,
      leftPane,
      showRightPane,
      uiReady,
      conversationOver,
      forceEmail,
      suppressWarmingUp,
      centralContent,
      invite,
      inviteError,
      createCandidateMode,
      mobileActiveMenuKey,
      activeMenuKey,
    } = this.state;

    const query: any = getQueryVariables();

    if (invite || (user.userId && !userDetails.userId)) {
      return (
        <div className="text-center loader">
          <h3>
            <Spinner /> {inviteError || translate('Signing you in...')}
          </h3>
        </div>
      );
    }

    const newCandidate = !user.userId || temporaryUser(userDetails);

    const toggleLeft = (leftPaneValue: any) => {
      setQueryVariables({ section: undefined });
      const state = centralContent ? { centralContent: null } : { leftPane: leftPaneValue };

      this.setState(state);
    };

    const isLoading = () => {
      const excludePageSpinner = [mainMenuKeys.recommended, mainMenuKeys.myJobs];
      return !excludePageSpinner.includes(activeMenuKey) && !newCandidate && !chat;
    };

    const isShowMessengerChat = () => {
      if (activeMenuKey !== mainMenuKeys.chat && centralContent) {
        return true;
      }
      if (activeMenuKey === mainMenuKeys.chat && chat && (!mobileMode || !leftPane)) {
        return true;
      }
      return false;
    };

    return (
      <div className="main-container candidate">
        <ModalSystemError
          key="modalSystemError"
          ref={(ref) => (this.modalSystemError = this.modalSystemError || ref)}
        />
        <div className="sub-container candidate">
          <div className="chatcontainer candidate">
            {mobileMode && (!leftPane || !!centralContent) && (
              <MessengerMobileHeader
                user={user}
                userDetails={userDetails}
                userProfile={userProfile}
                title={this.getHeaderTitle()}
                toggleLeft={toggleLeft.bind(this)}
                centralContent={!!centralContent}
                dispatchSignOut={dispatchSignOut}
              />
            )}

            {isLoading() && (
              <div className="text-center loader chat-loader">
                <h3>
                  <Spinner /> Loading...
                </h3>
              </div>
            )}

            {(chat || uiReady) && (
              <div className="messenger-container candidate">
                {(!mobileMode || leftPane || mobileActiveMenuKey) && (
                  <MessengerLeftMenu
                    user={user}
                    userDetails={userDetails}
                    messages={messages}
                    pipelines={pipelines}
                    loadingPipelines={loadingPipelines}
                    pipelinesLoaded={pipelinesLoaded}
                    conversationOver={conversationOver}
                    newCandidate={newCandidate}
                    forceEmail={forceEmail}
                    mobileMode={mobileMode}
                    leftPane={leftPane}
                    location={location}
                    mobileActiveMenuKey={mobileActiveMenuKey}
                    // @ts-expect-error: chat doesn't exist on leftMenuModes. Possibly it should be undefined
                    toggleLeft={() => this.setState({ leftPane: leftMenuModes.chat })}
                    setCentralContent={this.setCentralContent}
                    centralContent={!!centralContent}
                    emitSendMessage={this.emitSendMessage}
                    dispatchSignOut={dispatchSignOut}
                    dispatchLoadPipelines={dispatchLoadPipelines}
                  />
                )}
                <div className="right">
                  {(chat || createCandidateMode) && (
                    <div
                      className="center-right-content"
                      style={{
                        opacity: query.rp ? '0.4' : '',
                      }}
                    >
                      <div className="message-container">
                        <div
                          id="messages-sticky-date"
                          className="text-center sticky-date"
                          style={{
                            display: 'none',
                          }}
                        />

                        {!mobileMode && (
                          <MessengerChatHeader userProfile={userProfile} dispatchSignOut={dispatchSignOut} />
                        )}

                        {isShowMessengerChat() && (
                          <MessengerChat
                            className={`tablet-mode-${mobileMode ? 'on' : 'off'} ${
                              showRightPane ? 'show' : 'hide'
                            }-pane`}
                            settings={settings}
                            user={user}
                            userDetails={userDetails}
                            chat={chat}
                            messages={messages}
                            socket={socket}
                            loadingMessages={loadingMessages}
                            emitSendMessage={this.emitSendMessage}
                            newCandidate={newCandidate}
                            suppressWarmingUp={suppressWarmingUp}
                            mobileMode={mobileMode}
                            toggleLeft={this.toggleLeft}
                            centralContent={centralContent}
                            showSystemError={this.showSystemError}
                            dispatchLoadMessages={dispatchLoadMessages}
                            dispatchMarkLocalRead={dispatchMarkLocalRead}
                            location={location}
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// @ts-expect-error
MessengerCandidate.contextTypes = {
  getSectionsOptions: PropTypes.func.isRequired,
};

// @ts-expect-error
MessengerCandidate.propTypes = {
  user: PropTypes.object.isRequired,
  userProfile: PropTypes.object.isRequired,
  settings: PropTypes.object.isRequired,
  socket: PropTypes.object,
  userDetails: PropTypes.object.isRequired,
  loadingUserDetails: PropTypes.bool.isRequired,
  chats: PropTypes.object.isRequired,
  loadingChats: PropTypes.bool.isRequired,
  chatsLoaded: PropTypes.bool.isRequired,
  chatsLoadError: PropTypes.string.isRequired,
  pipelines: PropTypes.array.isRequired,
  loadingPipelines: PropTypes.bool.isRequired,
  pipelinesLoaded: PropTypes.bool.isRequired,
  messages: PropTypes.object.isRequired,
  loadingMessages: PropTypes.object.isRequired,
  candidatesFilter: PropTypes.object.isRequired,
  searchQuery: PropTypes.object.isRequired,
  deletingCandidate: PropTypes.bool.isRequired,
  deleteCandidateSuccess: PropTypes.object.isRequired,
  deleteCandidateError: PropTypes.string.isRequired,
  updatingProfile: PropTypes.string.isRequired,
  loadingCandidatesFilter: PropTypes.bool.isRequired,
  mobileMode: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,

  // Auth
  dispatchSignInSuccess: PropTypes.func.isRequired,
  dispatchSignUp: PropTypes.func.isRequired,
  dispatchSignOut: PropTypes.func.isRequired,

  // API
  dispatchLoadUserDetails: PropTypes.func.isRequired,
  dispatchLoadCandidatesFilter: PropTypes.func.isRequired,
  dispatchLoadChats: PropTypes.func.isRequired,
  dispatchLoadMessages: PropTypes.func.isRequired,
  dispatchLoadPipelines: PropTypes.func.isRequired,
  dispatchSearchCandidates: PropTypes.func.isRequired,
  dispatchDeleteCandidate: PropTypes.func.isRequired,
  dispatchLoadPermissions: PropTypes.func.isRequired,

  // Utils
  dispatchMarkLocalRead: PropTypes.func.isRequired,
  dispatchChatSelected: PropTypes.func.isRequired,

  // IO
  dispatchNewMessage: PropTypes.func.isRequired,
  dispatchSendMessage: PropTypes.func.isRequired,
  dispatchUpdatingProfile: PropTypes.func.isRequired,
  dispatchProfileUpdated: PropTypes.func.isRequired,
  dispatchPipelineUpdated: PropTypes.func.isRequired,
  dispatchChangeStatus: PropTypes.func.isRequired,
  dispatchCandidateProfileUpdated: PropTypes.func.isRequired,
  dispatchConversationWorkflowChanged: PropTypes.func.isRequired,
  dispatchOmnichatUpdated: PropTypes.func.isRequired,
  dispatchCandidateRegistrationCompleted: PropTypes.func.isRequired,
};

const prepareGlobalSelector = (value: any) => prepareSelector('global', value);
const prepareMessengerSelector = (value: any) => prepareSelector('candidate', value);
const mapStateToProps = createStructuredSelector({
  user: prepareGlobalSelector('user'),
  userProfile: prepareGlobalSelector('userProfile'),
  settings: prepareGlobalSelector('settings'),
  userDetails: prepareMessengerSelector('userDetails'),
  loadingUserDetails: prepareMessengerSelector('loadingUserDetails'),
  chats: prepareMessengerSelector('chats'),
  loadingChats: prepareMessengerSelector('loadingChats'),
  chatsLoaded: prepareMessengerSelector('chatsLoaded'),
  chatsLoadError: prepareMessengerSelector('chatsLoadError'),
  messages: prepareMessengerSelector('messages'),
  pipelines: prepareMessengerSelector('pipelines'),
  loadingPipelines: prepareMessengerSelector('loadingPipelines'),
  pipelinesLoaded: prepareMessengerSelector('pipelinesLoaded'),
  loadingMessages: prepareMessengerSelector('loadingMessages'),
  candidatesFilter: prepareMessengerSelector('candidatesFilter'),
  searchQuery: prepareMessengerSelector('searchQuery'),
  deletingCandidate: prepareMessengerSelector('deletingCandidate'),
  deleteCandidateSuccess: prepareMessengerSelector('deleteCandidateSuccess'),
  deleteCandidateError: prepareMessengerSelector('deleteCandidateError'),
  updatingProfile: prepareMessengerSelector('updatingProfile'),
  loadingCandidatesFilter: prepareMessengerSelector('loadingCandidatesFilter'),
});

const mapDispatchToProps = (dispatch: any) => ({
  // Auth
  // @ts-expect-error
  dispatchSignInSuccess: (...args: any[]) => dispatch(signInSuccess(...args)),
  // @ts-expect-error
  dispatchSignUp: (...args: any[]) => dispatch(signUp(...args)),
  // @ts-expect-error
  dispatchSignOut: () => dispatch(signOut()),

  // API
  dispatchLoadUserDetails: () => dispatch(loadUserDetails()),
  dispatchLoadCandidatesFilter: () => dispatch(loadCandidatesFilter()),
  // @ts-expect-error
  dispatchLoadChats: (...args: any[]) => dispatch(loadChats(...args)),
  // @ts-expect-error
  dispatchLoadMessages: (...args: any[]) => dispatch(loadMessages(...args)),
  dispatchLoadPipelines: () => dispatch(loadPipelines()),
  // @ts-expect-error
  dispatchSearchCandidates: (...args: any[]) => dispatch(searchCandidates(...args)),
  // @ts-expect-error
  dispatchDeleteCandidate: (...args: any[]) => dispatch(deleteCandidate(...args)),
  // @ts-expect-error
  dispatchLoadPermissions: (...args: any[]) => dispatch(loadPermissions(...args)),

  // Utils
  // @ts-expect-error
  dispatchMarkLocalRead: (...args: any[]) => dispatch(markLocalRead(...args)),
  // @ts-expect-error
  dispatchChatSelected: (...args: any[]) => dispatch(chatSelected(...args)),

  // IO
  // @ts-expect-error
  dispatchNewMessage: (...args: any[]) => dispatch(onNewMessage(...args)),
  // @ts-expect-error
  dispatchSendMessage: (...args: any[]) => dispatch(sendMessage(...args)),
  // @ts-expect-error
  dispatchUpdatingProfile: (...args: any[]) => dispatch(onUpdatingProfile(...args)),
  // @ts-expect-error
  dispatchProfileUpdated: (...args: any[]) => dispatch(onProfileUpdated(...args)),
  // @ts-expect-error
  dispatchPipelineUpdated: (...args: any[]) => dispatch(onPipelineUpdated(...args)),
  // @ts-expect-error
  dispatchChangeStatus: (...args: any[]) => dispatch(onChangeStatus(...args)),
  // @ts-expect-error
  dispatchCandidateProfileUpdated: (...args: any[]) => dispatch(onCandidateProfileUpdated(...args)),
  // @ts-expect-error
  dispatchConversationWorkflowChanged: (...args: any[]) => dispatch(onConversationWorkflowChanged(...args)),
  // @ts-expect-error
  dispatchOmnichatUpdated: (...args: any[]) => dispatch(onOmnichatUpdated(...args)),

  dispatchCandidateRegistrationCompleted: () => dispatch(onCandidateRegistrationCompleted()),
});

// eslint-disable-next-line import/no-default-export
export default connect(mapStateToProps, mapDispatchToProps)(MessengerCandidate);
