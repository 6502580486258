import { ReactNode, Suspense, useCallback, useEffect, useState } from 'react';
import { InfiniteData, QueryObserverResult } from 'react-query';

import Fallback from '@eva/emf/app/shared/ui/Fallback';
import Slider from '@eva/emf/app/shared/ui/Slider';
import { useGenericContext, useSocketEvent } from '@eva/emf/app/shared/hooks';
import { setQueryVariables } from '@eva/emf/app/shared/functions';
import type { ListedResponse } from '@eva/emf/app/types/common-types';
import { Spinner } from '@eva/emf/app/shared/ui/Spinner';
import type { JobDto } from '@eva/types/dto';

import JobDetails from 'containers/JobDetails';
import { showChatMessageNotification } from 'containers/JobDetails/functions';

import type { WebchatMessagePayload } from 'types/entities';

import { useRecommendedJobsWorkflows } from './hooks';
import { getActiveJobWorkFlowId } from './functions';
import JobNotFound from './JobNotFound';
import RecommendedJobsList from './RecommendedJobsList';
import RecommendedJobsPlaceholder from './RecommendedJobsPlaceholder';

export interface MenuRecommendedProps {
  mobileMode: boolean;
  parentId: string;
  switchToChat: () => void;
  setCentralContent: (node?: ReactNode) => void;
}

const socketEvents = {
  webchatMessageSent: 'webchat-message-sent',
};

const MenuRecommended = ({ mobileMode, parentId, switchToChat, setCentralContent }: MenuRecommendedProps) => {
  const { socket } = useGenericContext();

  const onWebChatMessageSent = useCallback(
    (payload: WebchatMessagePayload) => {
      if (!mobileMode) {
        return;
      }

      if (!payload?.message?.ai) {
        return;
      }

      showChatMessageNotification(payload.message);
    },
    [mobileMode],
  );

  useSocketEvent(socketEvents.webchatMessageSent, onWebChatMessageSent);

  const { jobsWorkflows, isLoading } = useRecommendedJobsWorkflows();

  const [activeJobsWorkflowId, setActiveJobsWorkflowId] = useState<number>();

  const selectJobWorkflow = useCallback(
    (workflowId: number) => {
      setQueryVariables({
        workflowId,
      });
      if (workflowId !== activeJobsWorkflowId) {
        setQueryVariables({
          jobId: undefined,
        });
      }
      setActiveJobsWorkflowId(workflowId);
    },
    [activeJobsWorkflowId],
  );

  const onJobNotFound = useCallback(() => {
    setCentralContent(
      <div className="job-central-panel">
        <JobNotFound />
      </div>,
    );
  }, [setCentralContent]);

  useEffect(() => {
    if (!jobsWorkflows?.length) {
      setQueryVariables({
        workflowId: undefined,
      });
      onJobNotFound();
      return;
    }

    const workflowId = getActiveJobWorkFlowId(jobsWorkflows);

    setQueryVariables({
      workflowId,
    });

    setActiveJobsWorkflowId(workflowId);
  }, [jobsWorkflows, onJobNotFound]);

  const setCentralContentJobDetails = useCallback(
    (
      jobCode: string,
      onApply: (j: JobDto) => Promise<QueryObserverResult<InfiniteData<ListedResponse<JobDto>>, unknown>>,
    ) => {
      setCentralContent(
        <div className="job-central-panel">
          <JobDetails socket={socket} jobCode={jobCode} onApply={onApply} closeJobDetails={switchToChat} />
        </div>,
      );
    },
    [setCentralContent, socket, switchToChat],
  );

  const onSelectJob = useCallback(
    (
      job: JobDto,
      onApply: (j: JobDto) => Promise<QueryObserverResult<InfiniteData<ListedResponse<JobDto>>, unknown>>,
    ) => {
      if (!job) {
        setQueryVariables({
          jobId: undefined,
        });
        return;
      }

      setQueryVariables({
        jobId: job.jobId,
      });

      setCentralContentJobDetails(job.code, onApply);
    },
    [setCentralContentJobDetails],
  );

  if (isLoading) {
    return (
      <div className="text-center">
        <Spinner />
      </div>
    );
  }

  if (!jobsWorkflows?.length) {
    return <RecommendedJobsPlaceholder />;
  }

  return (
    <div className="text-center">
      <div>
        <div className="clearfix" />
        <Slider width={0}>
          <ul className="nav nav-tabs">
            {jobsWorkflows?.map((jobWorkflow) => (
              <li
                key={jobWorkflow.workflowId}
                className={`${jobWorkflow.workflowId === activeJobsWorkflowId ? 'active' : ''}`}
              >
                <a
                  onClick={(env) => {
                    env.preventDefault();
                    selectJobWorkflow(jobWorkflow.workflowId);
                  }}
                  href={jobWorkflow.name}
                >
                  {jobWorkflow.name}
                </a>
              </li>
            ))}
          </ul>
        </Slider>
      </div>

      {activeJobsWorkflowId && (
        <RecommendedJobsList
          mobileMode={mobileMode}
          parentId={parentId}
          workflowId={activeJobsWorkflowId}
          onSelectJob={onSelectJob}
          onJobNotFound={onJobNotFound}
          setCentralContentJobDetails={setCentralContentJobDetails}
        />
      )}
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default (props: any) => (
  <Suspense fallback={<Fallback />}>
    <MenuRecommended {...props} />
  </Suspense>
);
